import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../../../../ui-treact/components/misc/Headings.js";
// import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
// import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import customerSupportIllustrationSrc from "src/images/faqs.jpg";
import PlusIcon from "../svgs/PlusIcon.js";
import MinusIcon from "../svgs/MinusIcon.js";

// const StyledDiv = styled.div``
const Container = tw.div`relative w-full bg-white`;
const Content = tw.div`max-w-screen-xl mx-auto py-8`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.img_src}");`,
  props.image_contain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.image_shadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-900`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-base font-semibold text-secondary-900`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "FAQs",
        heading: (
            <>
                Do you have <span tw="text-primary-500">Questions ?</span>
            </>
          ),
        description: "Our team is about transparency and honesty. We love questions and we love honest answers!",
        img_src: customerSupportIllustrationSrc,
        image_contain: true,
        image_shadow: false,
        faqs: [
          {
            question: "What is a website audit?",
            answer:
              "The first thing we do when meeting a new client is to run a website analysis. This can range in depth depending on your goals and website needs. We utilize third party toolssuch as Google Pagespeed, Google Mobile-Friendly Test, GT Metrix, Moz, ScreamingFrog, and more. These reports look at a variety of different specs of your site to give usa general idea of how search engines see you. Information you can expect to find in a website audit includes: loading times, metadata information, site crawl errors,site rankings and comparisons."
          },
          {
            question: "What eCommerce platforms do you work with?",
            answer:
              "While we have worked with most eCommerce platforms out there, we primarily work withShopify, Woo Commerce/WordPress, and Big Commerce, due to their popularity."
          },
          {
            question: "Can you fix my existing site or do I need a new one?",
            answer:
              "We strive for ultimate honey in this area! We ultimately want you to work on CMS of yourliking and will do our best to work on your existing site. There are instances when it ismore beneficial to start from scratch than spend time correcting the old."
          },
          {
            question: "How fast can you get my website?",
            answer:
              "While every site is different and each client has their own set of needs and requirements, we strive to get every client to at least a B on GTMetrix and above 80%. For those whoare looking specifically for speed we offer packages to remake your site specifically designed to get you to 95-100%. Keep in mind that every second gained equates tohigher conversion rates and sales."
          },
          {
            question: "How does your pricing work?",
            answer:
              "At Brandhouse DNA we strive to be aone stop shop. We have always found it most convenient and successful when you have one team working on your website for optimal communication and increased rate of project completion. Our services are all tailored and priced to your specific needs. We can price per project or for ongoing cases chargea flat monthly rate. All clients are given an upfront quote outlining all services to be completed as well as any “what ifs” along the way. We like to give you the bottom line without any surprises along theway. Contact us for your project quote."
          },
          {
            question: "2021 what is your biggest tip for clients?",
            answer:
              "Pagespeed, pagespeed, pagespeed! Do what you can to keep your site (especially mobile) loading quickly. While videos, animation, and other moving sections can add great “wow” to your site, if it takes longer than 4 seconds to load, you’ll lose most your visitors and forgo the chance to “wow” them at all. Most users want easy functionality tofind and navigate their needs."
          }
        ],
        ...finalData
  } 

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image image_contain={content.image_contain} image_shadow={content.image_shadow} img_src={content.img_src} />
          </Column>
          <Column>
            <FAQContent>
              {content.sub_heading ? <Subheading>{content.sub_heading}</Subheading> : null}
              <Heading>{content.heading}</Heading>
              <Description>{content.description}</Description>
              <FAQSContainer>
                {content.faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
